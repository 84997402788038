const Colors = {
  primary: '#933ec5',
  primaryHover: '#e6d6ef',
  secondary: '#7697da',
  secondaryHover: '#d2dae4',
  dark: '#17192e',
  text: '#080707'
}

export default Colors
