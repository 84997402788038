import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 25px;
  width: 500px;
  height: 50px;
  border: 1px solid #eee;
  background-color: #fff;
  box-shadow: 0 1px 6px 1px rgba(186, 186, 186, 0.2);
  .icon {
    margin-left: 20px;
    color: #d6d6d6;
  }
  .black {
    color: #000;
  }
  .password {
    margin-right: 20px;  
  }
  .clear {
    margin-left: 30px;
    margin-right: 10px;
  }
  .passIcon {
    fill: #D6D6D6;
    min-width: 16px;
  }
  .passEye {
    tab-index: 0;
    cursor: pointer;
  }
  .clearIcon {
    tab-index: 0;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }
  .blackSvg {
    fill: #000;
  }
  .red {
    fill: #de4c4c;
  }
`

export const StyledInput = styled.input`
  color: ${props => (props.error ? '#de4c4c;' : '#000;')}
  box-sizing: border-box;
  border: 0;
  font-family: StolzlLight, serif;
  letter-spacing: 0;
  font-size: 14px;
  padding: 12px 10px;
  width: 80%;
  @media (max-width: 1200px) and (min-width: 701px) {
    padding: 10px 8px;
    font-size: 11.2px;
  }
  @media (max-width: 425px) {
    font-size: 16px;
  }
  ::placeholder {
    color: #000;
  }
  &:focus {
    outline: none;
  }
`
