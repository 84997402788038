import styled, { keyframes } from 'styled-components'
import Colors from '../../constants/colors'

const rotating = keyframes`
  100%{
    transform: rotate(360deg);
  }
`

const animateStripes = keyframes`
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 60px 0;
    }
`

export const Container = styled.img`
  width: 50px;
  height: 50px;
  margin-left: 50px;
  margin-top: 50px;
  animation: ${rotating} 6s linear infinite;
`

export const LineContainer = styled.div`
  height: 3px;
  width: 100vw;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    ${Colors.primary} 25%,
    transparent 25%,
    transparent 50%,
    ${Colors.primary} 50%,
    ${Colors.primary} 75%,
    transparent 75%,
    transparent
  );
  animation: ${animateStripes} 1s linear infinite;
`
