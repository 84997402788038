import getConfig from 'next/config'
import fetch from 'isomorphic-unfetch'
import { i18n } from '../i18n'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

const defaultLimit = 30

export const generateQueryString = queryObject => Object.entries(queryObject)
  .filter(([, value]) => value !== null)
  .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
  .join('&')

export const fetchMainPageData = async (artistID, language = i18n.language) => {
  let url = '/api/v1/main_page_data/'
  if (artistID) {
    url += `?artist=${artistID}`
  }

  try {
    const res = await fetch(url, {
      headers: {
        Accept: 'application/json',
        'Accept-Language': language,
        Secret: publicRuntimeConfig.secret
      }
    })
    return await res.json()
  } catch (error) {
    console.error(error)
  }
  return null
}

export const fetchList = async (
  page = 0,
  filters,
  entityName,
  limit = defaultLimit,
  language = i18n.language,
  userToken
) => {
  const getListUrl = `/api/v1/${entityName}/?limit=${limit}`
  const token = userToken
  const offset = page * limit
  let url = `${getListUrl}&offset=${offset.toString()}`

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    url = `${serverRuntimeConfig.serverAddress}${url}`
  }

  url += `&${generateQueryString(filters)}`

  try {
    const res = await fetch(url, {
      headers: {
        Authorization: token ? `JWT ${token}` : undefined,
        Accept: 'application/json',
        'Accept-Language': language,
        Secret: publicRuntimeConfig.secret
      }
    })

    return await res.json()
  } catch (error) {
    console.error(error)
  }
  return null
}

export const normalizeDurationTime = (start, end) => {
  if (!start || !end) return ''
  return `${start.substr(11, 5)} - ${end.substr(11, 5)}`
}

export const differSocialsLinks = (links, domains) => {
  const others = []
  const socials = {}
  if (!links) return { others, socials }
  links.forEach((link) => {
    if (
      !Object.keys(domains).some((social) => {
        if (link.indexOf(domains[social]) !== -1) {
          socials[social] = link
          return true
        }
        return false
      })
    ) {
      others.push(link)
    }
  })
  return { others, socials }
}

export const matchPlural = (number, [one, few, many]) => {
  const numberStr = number.toString()
  const lastDigit = parseInt(numberStr.substring(numberStr.length - 1, numberStr.length), 10)
  const lastTwoDigits = parseInt(numberStr.substring(numberStr.length - 2, numberStr.length), 10)

  if (lastTwoDigits > 9 && lastTwoDigits < 21) {
    return many
  }

  if (lastDigit === 1) {
    return one
  }

  if (lastDigit > 1 && lastDigit < 5) {
    return few
  }

  return many
}

export const prepareLink = (link) => {
  if (!link || typeof (link) !== 'string' || link.startsWith('/')
    || link.includes('://') || link.startsWith('mailto:')) return link
  return `http://${link}`
}
