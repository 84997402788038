/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Telegram = ({ style, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} className={className}>
    <path
      fillRule="nonzero"
      d="M16.968 12.188l-4.608 4.75c-.162.167-.266.39-.296.634l-.157 1.29c-.02.171-.24.189-.282.022l-.603-2.352a.656.656 0 0 1 .245-.701l5.583-3.814c.1-.068.204.083.118.171zM30 15c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15zm-10.48-4.976L7.226 14.632a.34.34 0 0 0 .004.642l2.995 1.087 1.16 3.624a.357.357 0 0 0 .56.164l1.67-1.323a.51.51 0 0 1 .606-.017l3.012 2.125a.356.356 0 0 0 .553-.208l2.206-10.313c.057-.266-.212-.487-.472-.39z"
    />
  </svg>
)

Telegram.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Telegram.defaultProps = {
  style: {},
  className: undefined
}
