import PropTypes from 'prop-types'
import Head from 'next/head'
import { withNamespaces } from '../../i18n'

const MetaContent = ({ meta: { title, description, image = '/static/images/main-cover_cr.jpg' }, t }) => {
  const metaDescription = description || t('DESCRIPTION_SEO')
  const metaImage = image || '/static/images/main-cover_cr.jpg'

  return (
    <Head>
      {title && <title>{`${title} | Ural Music Night`}</title> }
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta name="description" content={description} />}
      <meta name="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImage} />
    </Head>
  )
}

MetaContent.propTypes = {
  meta: PropTypes.instanceOf(Object).isRequired,
  t: PropTypes.func.isRequired
}
MetaContent.defaultProps = {
}

export default withNamespaces(['about'])(MetaContent)
