/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Vkontakte = ({ style, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} className={className}>
    <path
      fillRule="nonzero"
      d="M14.962 0C6.699 0 0 6.699 0 14.962s6.699 14.961 14.962 14.961 14.961-6.698 14.961-14.961S23.225 0 14.962 0zm7.59 16.58c.696.681 1.434 1.322 2.06 2.074.278.332.539.676.738 1.062.284.552.028 1.156-.466 1.19l-3.065-.002c-.792.066-1.422-.253-1.953-.795-.424-.43-.817-.892-1.226-1.338a2.866 2.866 0 0 0-.552-.49c-.417-.272-.78-.19-1.02.247-.244.445-.3.937-.323 1.431-.033.723-.251.912-.976.946-1.55.072-3.02-.163-4.387-.944-1.205-.69-2.138-1.662-2.951-2.763-1.584-2.146-2.796-4.502-3.886-6.926-.245-.546-.066-.838.537-.849 1-.02 2-.017 3 0 .408.006.677.239.834.623.54 1.33 1.203 2.595 2.034 3.769.221.313.447.624.769.845.355.244.625.163.793-.233a2.57 2.57 0 0 0 .176-.792c.08-.924.09-1.849-.048-2.77-.086-.577-.41-.949-.985-1.058-.292-.055-.25-.164-.107-.33.247-.29.478-.468.94-.468l3.464-.001c.546.107.668.352.742.902l.003 3.848c-.006.213.107.844.49.983.305.101.507-.144.69-.338.831-.882 1.423-1.922 1.953-2.998.234-.475.435-.967.631-1.459.146-.364.372-.543.783-.537l3.335.004c.098 0 .198.001.296.018.562.096.716.338.542.886-.274.862-.806 1.58-1.325 2.299-.558.77-1.152 1.513-1.703 2.287-.507.707-.467 1.063.162 1.677z"
    />
  </svg>
)

Vkontakte.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Vkontakte.defaultProps = {
  style: {},
  className: undefined
}
