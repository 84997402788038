import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { inject, observer } from 'mobx-react'

import Button from '../Button'
import { withNamespaces } from '../../i18n'

import {
  ContentDiv, GlobalStyle
} from './styles'


@inject('store')
@observer
class SuccessModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  }

  render() {
    const {
      t,
      store: { successModal, toggleSuccessModal }
    } = this.props

    return (
      <>
        <GlobalStyle />
        <ReactModal
          ariaHideApp={false}
          isOpen={successModal.isOpen}
          onRequestClose={() => {
            toggleSuccessModal(false)
          }}
          overlayClassName="SuccessModal_Overlay"
          className="SuccessModal_Content"
        >
          <ContentDiv>
            <div className="authSuccessContainer">
              <div className="title">{successModal.message}</div>
              <Button
                onPress={() => {
                  toggleSuccessModal(false)
                }}
              >
                {t('OK')}
              </Button>
            </div>
          </ContentDiv>
        </ReactModal>
      </>
    )
  }
}

export default withNamespaces(['auth'])(SuccessModal)
