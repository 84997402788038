import styled, { css } from 'styled-components'
import Colors from '../../constants/colors'

export const ContainerDiv = styled.div`
  width: 100%;
  position: absolute;
  background-color: transparent;
  z-index: 3;
  @media (max-width: 768px) {
    display: none;
  }
  .headerContent {
    display: flex;
    flex-direction: row;
    margin-top: 45px;
    margin-bottom: 70px;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff;
    align-items: center;
    font-family: StolzlLight, serif;
    background-color: #000;
    padding: 0px 60px;
    max-width: 1440px;
    @media (max-width: 1200px) {
      margin-top: 28px;
      margin-bottom: 6vw;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0;
    }
    @media (max-width: 1000px) {
      padding: 0px 50px;
    }
    @media (max-width: 425px) {
      padding: 0px 20px;
    }
  }
  .searchIcon {
    height: 30px;
    z-index: 2;
    cursor: pointer;
    margin-left: 25px;
    margin-right: 25px;
    transition-duration: 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .profileIcon {
    z-index: 2;
    cursor: pointer;
    transition-duration: 0.2s;
    &:hover {
      transform: scale(1.1);
    }
  }
  .avatar {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 15px;
  }
`

export const MenuItemA = styled.a`
  margin-right: 2.5vw;
  cursor: pointer;
  transition-duration: 0.2s;
  z-index: 2;
  text-decoration: none;
  color: #fff;
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
  &:visited {
    color: #fff;
  }
  &:hover {
    color: ${Colors.primaryHover};
  }
  &:active {
    opacity: 0.8;
  }
  ${props => props.selected
    && css`
      color: ${Colors.primary};
      font-family: StolzlMedium, serif;
      letter-spacing: 1px;
      @media (max-width: 1200px) {
        letter-spacing: 0;
      }
      &:visited {
        color: ${Colors.primary};
      }
    `}
`

export const SwitchContainerDiv = styled.div`
  display: flex;
  justify-content: center;
  font-family: StolzlMedium, serif;
  font-size: 20px;
  letter-spacing: 2px;
  position: relative;
  cursor: pointer;
  z-index: 2;
  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.2px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`

export const SwitchButtonA = styled.a`
  border: none;
  margin: 0 5px;
  transition-duration: 0.2s;
  ${props => props.checked
    && css`
      color: ${Colors.primary};
    `}
  &:focus {
    outline: none;
  }
  &:hover {
    color: ${Colors.primaryHover};
  }
  &:active {
    opacity: 0.8;
  }
`

export const BackgroundImg = styled.img`
  position: absolute;
  transform: scaleY(-1);
  bottom: 0;
  width: 100vw;
  z-index: 1;
  @media (min-width: 1441px) {
    bottom: -1.5vw;
  }
`
