/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Twitter = ({ style, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} className={className}>
    <path
      fill="#000"
      fillRule="nonzero"
      d="M14.896 0C6.682 0 0 6.682 0 14.896 0 23.108 6.682 29.79 14.896 29.79c8.212 0 14.895-6.683 14.895-14.895C29.791 6.682 23.11 0 14.896 0zm6.645 11.486c.006.148.01.297.01.446 0 4.53-3.448 9.753-9.756 9.753a9.698 9.698 0 0 1-5.255-1.54 6.881 6.881 0 0 0 5.076-1.42 3.432 3.432 0 0 1-3.203-2.38 3.456 3.456 0 0 0 1.548-.058 3.429 3.429 0 0 1-2.749-3.405c.462.256.99.411 1.552.428a3.426 3.426 0 0 1-1.524-2.853c0-.628.168-1.218.463-1.724a9.735 9.735 0 0 0 7.067 3.583 3.428 3.428 0 0 1 5.842-3.128 6.843 6.843 0 0 0 2.178-.832 3.444 3.444 0 0 1-1.51 1.897 6.816 6.816 0 0 0 1.97-.539 6.896 6.896 0 0 1-1.71 1.772z"
    />
  </svg>

)

Twitter.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Twitter.defaultProps = {
  style: {},
  className: undefined
}
