import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Lock } from '../../static/svgs/Lock'
import { StyledInput, Container } from './styles'

export default class TextInput extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    icon: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    onEnterPress: PropTypes.func,
    value: PropTypes.string,
    password: PropTypes.bool,
    error: PropTypes.bool,
    search: PropTypes.bool
  }

  static defaultProps = {
    onChange: () => {},
    icon: undefined,
    className: undefined,
    placeholder: '',
    onEnterPress: () => {},
    value: '',
    password: false,
    error: false,
    search: false
  }

  state = {
    text: this.props.value,
    showPass: !this.props.password
  }

  handleChange = (event) => {
    this.setState({ text: event.target.value })
    this.props.onChange(event.target.value)
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.props.onEnterPress()
    }
  }

  focus = () => {
    this.input.focus()
  }

  render() {
    const { text, showPass } = this.state
    const {
      icon, className, placeholder, password, error, search
    } = this.props
    const textSize = text.length
    return (
      <Container className={className}>
        {icon && <FontAwesomeIcon className={`icon ${textSize > 0 && 'black'}`} icon={icon} />}
        {password && <Lock className={`icon passIcon ${error && 'red'} ${textSize > 0 && 'blackSvg'}`} />}
        <StyledInput
          ref={ref => (this.input = ref)}
          type={showPass ? 'text' : 'password'}
          value={text}
          onChange={this.handleChange}
          placeholder={placeholder}
          onKeyPress={this.handleKeyPress}
          error={error}
        />
        {password && (
          // eslint-disable-next-line jsx-a11y/interactive-supports-focus
          <span
            role="radio"
            aria-checked={showPass}
            className="passEye"
            onClick={() => this.setState({ showPass: !showPass })}
          >
            <FontAwesomeIcon
              className={`icon password ${textSize > 0 && 'black'}`}
              icon={showPass ? 'eye-slash' : 'eye'}
            />
          </span>
        )}
        {search && textSize > 0 && (
          <div
            role="button"
            tabIndex={0}
            className="clearIcon"
            onClick={() => {
              this.setState({ text: '' })
              this.props.onChange('')
              this.props.onEnterPress()
            }}
          >
            <FontAwesomeIcon className="icon clear" icon="times-circle" />
          </div>
        )}
      </Container>
    )
  }
}
