import styled, { css } from 'styled-components'
import Colors from '../../constants/colors'

const Line = css`
  .line {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: 2px;
    color: #ffffff;
    :not(:last-child) {
      margin-bottom: 16px;
    }
    @media (max-width: 1000px) {
      font-size: 10px;
      line-height: 1.8;
      letter-spacing: 1.3px;
    }
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: 1.57;
      letter-spacing: normal;
    }
  }
`

export const Container = styled.footer`
  z-index: 100;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;
  .footerContent {
    flex: 1;
    width: 100%;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: ${props => (props.whiteStyle ? '#fff' : '#000')};
    padding-bottom: 60px;
    @media (max-width: 1000px) {
      padding-bottom: 40px;
    }
    @media (max-width: 600px) {
      justify-content: center;
      padding-bottom: 30px;
    }
  }
`

export const FooterBack = styled.img`
  width: 100vw;
  margin-bottom: -10vw;
  @media (max-width: 1000px) {
    margin-bottom: -8vw;
  }
  @media (max-width: 600px) {
    margin-bottom: -7px;
  }
  @media (max-width: 475px) {
    margin-bottom: -38vw;
    transform: none;
  }
`

export const CenterDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 100%;
  top: -10px;
  @media (max-width: 600px) {
    position: relative;
  }
  @media (max-width: 475px) {
    top: 0;
  }
  ${Line}
  .line {
    color: ${props => (props.whiteStyle ? '#000' : '#fff')};
  }
  .doubletappLink {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
    padding: 0 10px 5px 10px;
    transition: 0.2s;
    border-bottom: 2px solid ${props => (props.whiteStyle ? '#fff' : '#000')};
    &:hover {
      border-bottom: 2px solid ${Colors.primary};
    }
  }
  .logo {
    width: 120px;
    @media (max-width: 1000px) {
      width: 90px;
      height: 20px;
    }
    @media (max-width: 600px) {
      width: 80px;
    }
  }
  .flexLine {
    display: flex;
    align-items: center;
    @media (max-width: 475px) {
      align-items: flex-end;
    }
  }
  @media (max-width: 475px) {
    margin-top: 50px;
  }
`

export const UMNImage = styled.img`
  width: 97px;
  z-index: 1;
  margin-bottom: 21px;
  cursor: pointer;
  transition-duration: 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 1000px) {
    width: 58px;
    height: 44px;
  }
  @media (max-width: 600px) {
    width: 80px;
    height: 60px;
  }
`

export const ContactsDiv = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 3;
  margin-left: 60px;
  @media (max-width: 1000px) {
    margin-left: 28px;
  }
  .header {
    font-family: StolzlMedium, serif;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 2px;
    color: ${props => (props.whiteStyle ? '#000' : '#fff')};
    text-transform: uppercase;
    margin-bottom: 16px;
    @media (max-width: 1000px) {
      font-size: 12px;
      letter-spacing: 1.2px;
    }
  }

  ${Line}

  .line {
    color: ${props => (props.whiteStyle ? '#000' : '#fff')};
    text-decoration: none;
    transition: 0.2s;
    border-bottom: 2px solid ${props => (props.whiteStyle ? '#fff' : '#000')};
    &:hover {
      border-bottom: 2px solid ${Colors.primary};
    }
  }
  .grants {
    color: ${props => (props.whiteStyle ? '#000' : '#fff')};
    font-size: 9px;
    line-height: 20px;
    letter-spacing: 1px;
    width: 33vw;
    @media (max-width: 1000px) {
      font-size: 5px;
      line-height: 15px;
    }
  }
`

export const SocialsDiv = styled.div`
  z-index: 1;
  margin-right: 59px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1000px) {
    margin-right: 31px;
  }
  .icons {
    .icon {
      @media (max-width: 1000px) {
        width: 18px;
        height: 18px;
      }
    }
  }
  .stores {
    display: flex;
    flex-direction: row;
    margin-right: -10px;
    @media (max-width: 1000px) {
      margin-right: -5px;
    }
    .link {
      transition-duration: 0.2s;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  ${Line}
  .line {
    color: ${props => (props.whiteStyle ? '#000' : '#fff')};
  }
  span {
    margin-top: 10px;
  }
`
