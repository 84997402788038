import PropTypes from 'prop-types'
import { Container, LineContainer } from './styles'

const Loading = ({ className, type }) => {
  if (type === 'circle') return <Container src="/static/images/loading.svg" alt="loading" className={className} />
  return <LineContainer className={className} />
}
Loading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
}

Loading.defaultProps = {
  className: '',
  type: 'circle'
}

export default Loading
