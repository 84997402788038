import styled from 'styled-components'

export const ContainerDiv = styled.div`
  position: absolute;
  z-index: 100;
  right: 70px;
  top: 115px;
  height: 320px;
  width: 405px;
  display: ${props => (props.open ? 'block' : 'none')};
  @media (max-width: 768px) {
    position: fixed;
    top: calc(50vh - 160px);
    right: calc(50vw - 202.5px);
  }
  @media (max-width: 450px) {
    width: 90vw;
    right: 5vw;
  }
  .closeArea {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    @media (max-width: 768px) {
      background-color: #00000050;
    }
  }
  .modal {
    height: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .avatar {
    border-radius: 50px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .authorizedAs {
    font-family: StolzlLight, serif;
    font-size: 16px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.88;
    letter-spacing: 2px;
    margin-top: 13px;
    color: #080707;
  }
  .userEmail {
    font-family: StolzlMedium, serif;
    font-size: 20px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: 2px;
    margin-top: 10px;
    color: #000000;
    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 1.6px;
    }
  }
  .name {
    text-transform: uppercase;
  }
  .avatarImg {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    object-fit: cover;
  }
`
