import getConfig from 'next/config'
import fetch from 'isomorphic-unfetch'
import { i18n } from '../i18n'

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig()

export const getName = async (token, language = i18n.language) => {
  let profileUrl = '/api/v1/me/'

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    profileUrl = `${serverRuntimeConfig.serverAddress}${profileUrl}`
  }

  try {
    if (token) {
      const res = await fetch(profileUrl, {
        headers: {
          Authorization: `JWT ${token}`,
          Accept: 'application/json',
          'Accept-Language': language,
          Secret: publicRuntimeConfig.secret
        },
        method: 'GET'
      })

      const resJson = await res.json()
      if (!res.ok) {
        return Promise.reject(resJson)
      }
      resJson.id = resJson.profile_id
      return resJson
    }
  } catch (error) {
    console.error(error)
  }
  return null
}

export const getProfile = async (token, language = i18n.language) => {
  let profileUrl = '/api/v1/profiles/me/'

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    profileUrl = `${serverRuntimeConfig.serverAddress}${profileUrl}`
  }

  try {
    if (token) {
      const res = await fetch(profileUrl, {
        headers: {
          Authorization: `JWT ${token}`,
          Accept: 'application/json',
          'Accept-Language': language,
          Secret: publicRuntimeConfig.secret
        },
        method: 'GET'
      })

      const resJson = await res.json()
      if (!res.ok) {
        return Promise.reject(resJson)
      }
      return resJson
    }
  } catch (error) {
    console.error(error)
  }
  return null
}

export const auth = async (credentials, authType, language = i18n.language) => {
  let regUrl = `/api/v1/auth/${authType}/`

  if (serverRuntimeConfig && serverRuntimeConfig.serverAddress) {
    regUrl = `${serverRuntimeConfig.serverAddress}${regUrl}`
  }

  try {
    const res = await fetch(regUrl, {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
        'Accept-Language': language,
        Secret: publicRuntimeConfig.secret
      },
      method: 'POST',
      body: JSON.stringify(credentials)
    })

    const resJson = await res.json()
    if (!res.ok) {
      return Promise.reject(resJson)
    }
    resJson.user.id = resJson.user.profile_id
    return resJson
  } catch (error) {
    console.error(error)
  }
  return null
}
