import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 1;
`

export const IconLink = styled.a`
  transition-duration: 0.2s;
  :hover {
    transform: scale(1.3);
  }
  :not(:last-child) {
    margin-right: 24px;
  }
`

export const Icon = styled.img`
  width: 30px;
  fill: ${props => (props.whiteFill ? '#fff' : '#000')}
`
