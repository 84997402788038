import { Component } from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import { inject, observer } from 'mobx-react'

import TextInput from '../TextInput'
import { withNamespaces, Router } from '../../i18n'

import { ContentDiv, GlobalStyle } from './styles'

@inject('store')
@observer
class SearchModal extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  }

  state = {
    text: ''
  }

  onEnterPress = () => {
    if (!this.input) return
    const { text } = this.input.state
    Router.push(`/search?text=${encodeURI(text)}`)
    this.props.store.changeSearchText(text)
    if (Router.route === '/search') this.props.store.toggleSearchModal(false)
  }

  render() {
    const {
      t,
      store: { searchModal, toggleSearchModal }
    } = this.props
    const { text } = this.state

    return (
      <>
        <GlobalStyle />
        <ReactModal
          ariaHideApp={false}
          isOpen={searchModal.isOpen}
          onRequestClose={() => {
            toggleSearchModal(false)
          }}
          overlayClassName="SearchModal_Overlay"
          className="SearchModal_Content"
        >
          <ContentDiv>
            <TextInput
              className="input"
              ref={(ref) => {
                if (ref) {
                  ref.focus()
                  this.input = ref
                }
              }}
              value={text}
              onEnterPress={this.onEnterPress}
              placeholder={t('search-placeholder')}
            />
          </ContentDiv>
        </ReactModal>
      </>
    )
  }
}

export default withNamespaces(['common'])(SearchModal)
