/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Lock = ({ style, className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    style={style}
    className={className}
  >
    <path
      fillRule="nonzero"
      d="M11.194 5.25h-.916V4.083C10.278 1.832 8.358 0 6 0 3.641 0 1.722 1.832 1.722 4.083V5.25H.806a.299.299 0 0 0-.306.292v7.291C.5 13.477 1.048 14 1.722 14h8.556c.674 0 1.222-.523 1.222-1.167V5.542a.299.299 0 0 0-.306-.292zm-4.28 6.093a.284.284 0 0 1-.075.227.312.312 0 0 1-.228.097H5.39a.312.312 0 0 1-.228-.097.284.284 0 0 1-.076-.227l.193-1.655a1.138 1.138 0 0 1-.5-.938c0-.643.548-1.167 1.222-1.167.674 0 1.222.524 1.222 1.167 0 .377-.187.72-.5.938l.193 1.655zm1.53-6.093H3.556V4.083C3.556 2.797 4.652 1.75 6 1.75c1.348 0 2.444 1.047 2.444 2.333V5.25z"
    />
  </svg>

)

Lock.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Lock.defaultProps = {
  style: {},
  className: undefined
}
