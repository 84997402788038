import styled, { css } from 'styled-components'
import Colors from '../../constants/colors'

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
`
const lightMode = css`
    background-color: #D2DAE4;
    border-color: #D2DAE4;
    color: #fff;
`
const buttonCss = css`
  z-index: 10;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;
  min-width: 220px;
  border: 1px solid ${({ white, negative }) => {
    if (white) return '#fff'
    if (negative) return Colors.secondaryHover
    return Colors.primary
  }};
  padding: 0 20px;
  border-radius: 25px;
  font-family: StolzlLight, serif;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  color: #fff;
  background-color: ${({ white, negative }) => {
    if (white) return 'transparent'
    if (negative) return Colors.secondaryHover
    return Colors.primary
  }};
  transition-duration: 0.2s;
  ${p => !p.notScale && css`
    @media (max-width: 1200px) {
      transform: scale(0.8)
    }
    @media (max-width: 700px) {
      transform: scale(1)
    }
  `}
  &:hover {
    background-color: ${({ negative }) => (negative ? '#d2dae450' : Colors.primaryHover)};
    border-color: ${({ negative }) => (negative ? '#d2dae450' : Colors.primaryHover)};
    color: ${({ negative }) => (negative ? '#fff' : Colors.primary)};
  }
  &:active {
    border-color: ${({ negative }) => (negative ? Colors.secondaryHover : Colors.primary)};
    color: ${({ negative }) => (negative ? Colors.secondaryHover : Colors.primary)};
    background-color: transparent;
  }
  cursor: pointer;
  .icon {
    color: #fff;
    width: 12px;
    height: 12px;
  }
  .left {
    margin-right: 5px;
  }
  .right {
    margin-left: 5px;
  }
  ${({ light }) => (light ? lightMode : '')}
`

export const ButtonDiv = styled.div`
  ${buttonCss}
`

export const ButtonA = styled.a`
  ${buttonCss}
  text-decoration: none;
`
