import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from '../../i18n'
import { ContainerDiv, ButtonDiv, ButtonA } from './styles'

export default class Button extends PureComponent {
  static propTypes = {
    onPress: PropTypes.func,
    children: PropTypes.node.isRequired,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    className: PropTypes.string,
    href: PropTypes.string,
    target: PropTypes.string,
    link: PropTypes.string,
    humanLink: PropTypes.string,
    white: PropTypes.bool,
    light: PropTypes.bool,
    negative: PropTypes.bool,
    notScale: PropTypes.bool
  }

  static defaultProps = {
    onPress: () => {},
    iconLeft: undefined,
    iconRight: undefined,
    className: undefined,
    href: undefined,
    target: undefined,
    link: undefined,
    humanLink: undefined,
    white: false,
    light: false,
    negative: false,
    notScale: false
  }

  renderContent = () => {
    const { children, iconLeft, iconRight } = this.props
    return (
      <>
        {iconLeft && <FontAwesomeIcon className="icon left" icon={iconLeft} />}
        {children}
        {iconRight && <FontAwesomeIcon className="icon right" icon={iconRight} />}
      </>
    )
  }

  render() {
    const {
      onPress, className, href, target, link, white, humanLink, light, negative, notScale
    } = this.props
    return (
      <ContainerDiv className={className}>
        {href ? (
          <ButtonA light={light} white={white} href={href} target={target} negative={negative} notScale={notScale}>
            {this.renderContent()}
          </ButtonA>
        ) : link ? (
          <Link href={link} as={humanLink || link}>
            <ButtonA href={humanLink || link} light={light} white={white} negative={negative} notScale={notScale}>
              {this.renderContent()}
            </ButtonA>
          </Link>
        ) : (
          <ButtonDiv light={light} white={white} negative={negative} notScale={notScale} onClick={onPress}>
            {this.renderContent()}
          </ButtonDiv>
        )}
      </ContainerDiv>
    )
  }
}
