import { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { prepareLink } from '../../api/utils'

import icons from '../../constants/socialIcons'
import { IconContainer, IconLink } from './styles'

export default class SocialIcons extends PureComponent {
  static propTypes = {
    socials: PropTypes.instanceOf(Object).isRequired,
    className: PropTypes.string,
    large: PropTypes.bool,
    whiteFill: PropTypes.bool
  }

  static defaultProps = {
    className: undefined,
    whiteFill: false,
    large: false
  }

  render() {
    const {
      socials, whiteFill, className, large
    } = this.props

    if (Object.keys(socials).length === 0) return null

    return (
      <IconContainer className={className}>
        {Object.keys(socials).map((social) => {
          const linksArray = Array.isArray(socials[social]) ? socials[social] : [socials[social]]
          return linksArray.map(link => (
            <IconLink href={prepareLink(link)} target="_blank" rel="noopener noreferrer" key={link}>
              {icons[social](
                {
                  style: {
                    fill: whiteFill ? '#fff' : '#000',
                    width: large ? '50px' : '30px',
                    height: large ? '50px' : '30px'
                  },
                  className: 'icon'
                })}
            </IconLink>
          ))
        }
        )}
      </IconContainer>
    )
  }
}
