import React from 'react'
import Sidebar from 'react-sidebar'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import { inject, observer } from 'mobx-react'

import { withNamespaces, Link, i18n } from '../../i18n'
import PagesList from '../../constants/pages'
import {
  SideBarContainer, Menu, Button, LinkTextA, Language, Content
} from './styles'

@inject('store')
@observer
class MobileMenu extends React.Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    router: PropTypes.instanceOf(Object).isRequired,
    currentLanguage: PropTypes.string.isRequired,
    isTablet: PropTypes.bool.isRequired,
    store: PropTypes.instanceOf(Object).isRequired
  }

  toggleMenu = () => {}

  state = {
    sidebarOpen: false
  }

  onSetSidebarOpen = (open) => {
    this.setState({ sidebarOpen: open })
  }

  changeLanguage = () => {
    const language = i18n.language || this.props.currentLanguage
    if (language === 'en') i18n.changeLanguage('ru')
    else i18n.changeLanguage('en')
  }

  openModal = () => {
    const {
      t,
      store: { toggleLoginModal }
    } = this.props
    toggleLoginModal(true, t('auth:REGISTER_MESSAGE'))
    this.onSetSidebarOpen(false)
  }

  openSearch = () => {
    const {
      store: { toggleSearchModal }
    } = this.props
    toggleSearchModal(true)
  }

  render() {
    const {
      t,
      children,
      router,
      currentLanguage,
      isTablet,
      store: { user }
    } = this.props

    const language = i18n.language || currentLanguage

    return (
      <Sidebar
        contentId="sidebarContent"
        sidebar={(
          <SideBarContainer>
            <Menu>
              <div className="topButtons">
                {!user.avatar ? (
                  <img
                    className="profileIcon"
                    onClick={this.openModal}
                    alt="profile"
                    src={
                      user.token ? '/static/images/profile-icon-active.svg' : '/static/images/profile-icon-black.svg'
                    }
                  />
                ) : (
                  <img className="profileIcon avatar" onClick={this.openModal} src={user.avatar} alt="profile" />
                )}
                <img
                  onClick={this.openSearch}
                  className="searchIcon"
                  src="/static/images/searcher-black.svg"
                  alt="search"
                />
                <Button
                  className="closeIcon"
                  src="/static/images/cross.svg"
                  onClick={() => this.onSetSidebarOpen(false)}
                />
              </div>
              {PagesList.map(page => (
                <Link key={page.link} href={page.link}>
                  <LinkTextA href={page.link} selected={page.link === router.pathname}>
                    {t(page.title)}
                  </LinkTextA>
                </Link>
              ))}
            </Menu>
            <Language onClick={this.changeLanguage}>
              {language === 'en' ? 'RU' : 'EN'}
              {' '}
              <span className="full">{`(${t('language')})`}</span>
            </Language>
          </SideBarContainer>
)}
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight
        styles={{
          sidebar: {
            zIndex: '100',
            background: '#fafafa',
            width: isTablet ? '50vw' : '70vw'
          },
          overlay: {
            zIndex: 3
          }
        }}
      >
        <Content>
          <div className="mobileHeader">
            <Button src="/static/images/group-2.svg" onClick={() => this.onSetSidebarOpen(true)} />
          </div>
          {children}
        </Content>
      </Sidebar>
    )
  }
}

const MobileMenuWithRouter = withRouter(MobileMenu)
export default withNamespaces(['common', 'auth'])(MobileMenuWithRouter)
