import styled, { keyframes, css } from 'styled-components'
import Colors from '../../constants/colors'

const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`

export const ContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100vw;
  overflow: hidden;
  .routerLoading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
`

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  min-height: 100vh;
  width: 100vw;
  color: #000000;
  overflow: hidden;
  .coverImgContainer {
    background-color: #000;
    .bg__video {
      pointer-events: none;
      background-color: #000;
      object-fit: cover;
      width: 100vw;
      height: calc(42vw + 94px);
      min-height: 30vw;
      max-height: 100vw;
      margin-top: 4vw;
      margin-bottom: -4vw;
      @media (max-width: 1200px) {
        height: calc(42vw + 68px);
        margin-top: 4vw;
        margin-bottom: -4vw;
      }
      @media (max-width: 768px) {
        object-fit: cover;
        margin-top: -10px;
        margin-bottom: 0px;
      }
      @media (max-width: 425px) {
        margin-top: -20px;
        margin-bottom: -20px;
      }
    }
  }
  .coverImg {
    opacity: 0;
    animation: ${fadeIn} 1s ease;
    animation-fill-mode: forwards;
    background-color: ${Colors.secondaryHover};
    object-fit: cover;
    width: 100vw;
    height: 42vw;
    min-height: 30vw;
    max-height: 100vw;
    margin-top: 94px;
    @media (max-width: 1200px) {
      margin-top: 68px;
    }
    @media (max-width: 768px) {
      object-fit: cover;
      height: 42vh;
      width: 110vw;
      margin-top: 0;
    }
  }
  .coverDiv {
    display: flex;
    align-self: baseline;
    width: 100%;
    background-color: #000000;
  }
  .fadeBox {
    height: 100%;
    position: relative;
  }
  .fade {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to left, #000 0%, rgba(0, 0, 0, 0) 40%);
    @media (max-width: 768px) {
      display: none;
    }
  }
  .blockBox {
    width: 100vw;
    background-color: #000;
    @media (max-width: 768px) {
      display: none;
    }
  }
  .smallCoverImg {
    opacity: 0;
    animation: ${fadeIn} 1s ease;
    animation-fill-mode: forwards;
    width: initial;
    height: 42vw;
    max-height: 773px;
    min-height: 30vw;
    margin-top: 94px;
    background-color: ${Colors.secondaryHover};
    object-fit: cover;
    @media (max-width: 1200px) {
      margin-top: 68px;
    }
    @media (max-width: 768px) {
      object-fit: cover;
      height: 42vh;
      margin-top: 0;
      margin-bottom: -5px;
      width: 100vw;
    }
  }
`

export const GapLogoDiv = styled.div`
  position: relative;
  top: -20vw;
  width: 100vw;
  .images {
    position: relative;
  }
`

export const WhiteGapImg = styled.img`
  position: absolute;
  width: 100%;
  z-index: 2;
`

export const UMNWhiteImg = styled.img`
  position: absolute;
  top: ${props => (props.big ? '-6.0vw' : '-4.3vw')};
  right: ${props => (props.big ? '9.5vw' : '9.8vw')};
  width: ${props => (props.big ? '24.6vw' : '16.2vw')};
  z-index: 1;
  @media (max-width: 425px) {
    top: -10.5vw;
    right: 6.4vw;
    width: 41vw;
  }
`

export const UMNBlackImg = styled.img`
  position: absolute;
  top: ${props => (props.big ? '1.6vw' : '1.6vw')};
  right: ${props => (props.big ? '10.5vw' : '9.9vw')};
  width: ${props => (props.big ? '24.9vw' : '16.5vw')};
  z-index: 2;
  @media (max-width: 425px) {
    top: 4.4vw;
    right: 10vw;
    width: 36vw;
  }
`

export const PlayIconDiv = styled.div`
  opacity: 0.7;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: #ffffff;
  z-index: 3;
  right: 5vw;
  top: -100px;
  cursor: pointer;
  @media (max-width: 425px) {
    transform: scale(0.7);
    top: -85px;
  }
  .icon {
    height: 20px;
    color: #000000;
  }
  .playIcon {
    margin-left: 3px;
  }
`

export const ChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  color: #000000;
  z-index: 2;
  margin-top: 45px;
  @media (max-width: 425px) {
    margin-top: 30px;
  }
`
