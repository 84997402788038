/* eslint-disable jsx-a11y/no-static-element-interactions,
jsx-a11y/no-noninteractive-tabindex,
jsx-a11y/click-events-have-key-events */
import { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { inject, observer } from 'mobx-react'
import ReactSVG from 'react-svg'
import { withNamespaces } from '../../i18n'
import Button from '../Button'
import { ContainerDiv } from './styles'

const CustomButton = styled(Button)`
  margin-top: 18px;
`

@inject('store')
@observer
class ProfileWindow extends Component {
  static propTypes = {
    t: PropTypes.func.isRequired,
    store: PropTypes.instanceOf(Object)
  }

  static defaultProps = {
    store: {}
  }

  render() {
    const {
      t,
      store: {
        profileWindow: { isOpen },
        toggleLoginModal,
        toggleLogoutModal,
        user: {
          email, avatar, first_name, last_name
        }
      }
    } = this.props

    if (!isOpen) return null

    return (
      <ContainerDiv open={isOpen}>
        <div className="modal">
          <div className="avatar">
            {avatar ? (
              <img className="avatarImg" src={avatar} alt="" />
            ) : (
              <ReactSVG className="avatarSvg" src="/static/images/default-user-avatar.svg" />
            )}
          </div>
          <span className="authorizedAs">{t('LOGGED_IN_AS')}</span>
          {first_name === '' && last_name === '' ? (
            <span className="userEmail" style={{ fontSize: email.length > 20 ? `${350 / email.length}px` : '20px' }}>
              {email}
            </span>
          ) : (
            <span className="userEmail name">{`${first_name}${last_name !== '' && ' '}${last_name}`}</span>
          )}
          <CustomButton
            onPress={() => {
              toggleLogoutModal(true)
            }}
          >
            {t('LOG_OUT')}
          </CustomButton>
        </div>
        <div
          className="closeArea"
          onClick={() => {
            toggleLoginModal(false, '')
          }}
        />
      </ContainerDiv>
    )
  }
}

export default withNamespaces(['auth'])(ProfileWindow)
