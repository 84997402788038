import styled, { createGlobalStyle } from 'styled-components'
import Colors from '../../constants/colors'

export const GlobalStyle = createGlobalStyle`
  .UserModal_Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.70);
    z-index: 100;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    outline: none;
    &::after {
      background-image: url('/static/images/cross-circle.svg');
      background-size: 50px 50px;
      content: "";
      width: 50px;
      height: 50px;
      position: absolute;
      right: 50px;
      top: 75px;
      cursor: pointer;
      @media (max-width: 1200px) {
        right: 30px;
        top: 45px;
      }
      @media (max-width: 700px) {
        display: none;
      }
    }
  }
  .UserModal_Content {
    position: absolute;
    height: 640px;
    width: 880px;
    top: calc(50vh - 320px);
    bottom: 50px;
    left: calc(50vw - 440px);
    background-color: transparent;
    -webkit-overflow-scrolling: touch;
    outline: none;
    @media (max-width: 1200px) {
      height: 510px;
      top: calc(50vh - 255px);
      width: 700px;
      left: calc(50vw - 350px);
    }
    @media (max-width: 700px) {
      width: calc(100vw - 40px);
      left: 20px;
      height: auto;
      top: 100px;
    }
  }
`

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 2px;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 1200px) {
    font-size: 12.8px;
    line-height: 24px;
    letter-spacing: 1.6px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 50px;
  }
  .passwordHint {
    font-size: 10px;
    line-height: 2.2;
    letter-spacing: normal;
    margin-bottom: 21px;
    margin-top: 10px;
    width: 90%;
  }
  .description {
    width: 321px;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    @media (max-width: 1200px) and (min-width: 701px)  {
      font-size: 11.2px;
      line-height: 17.6px;
    }
    @media (max-width: 700px) {
      width: 90%;
    }
  }
  .passwordLink {
    cursor: pointer;
    outline: none;
    background: none;
    border: none;
    text-decoration: underline;
    font-family: StolzlLight, serif;
    letter-spacing: normal;
    font-size: 14px;
    line-height: 1.57;
    margin-bottom: 22px;
    color: #933ec5;
  }
  .title {
    font-family: StolzlMedium, serif;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 25px;
    width: 70%;
    @media (max-width: 1200px) and (min-width: 701px) {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 1.6px;
    }
  }
  .red {
    color: #de4c4c;
  }
  .text {
    margin-bottom: 25px;
    padding: 0 30px;
    @media (max-width: 1200px) {
      padding: 0 25px;
    }
  }
  .side {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    @media (max-width: 700px) {
      padding-bottom: 20px;
    }
  }
  .darkSide {
    @media (max-width: 700px) {
      display: none;
    }
    color: #fff;
    background-color: ${Colors.primary};
  }
  .lightSide {
    color: #000;
    @media (max-width: 700px) {
      padding-top: 25px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .authSuccessContainer {
    flex: 1;
    height: 100%;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      width: 100%;
    }
  }
  .otherTabButton {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${Colors.primary};
    width: calc(100% + 2px);
    height: 70px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    order: ${({ registrationTab }) => (registrationTab ? 1 : 0)};
    padding-top: ${({ registrationTab }) => (registrationTab ? '10px' : '0px')};
    margin-bottom: ${({ registrationTab }) => (registrationTab ? '0px' : '-10px')};
    margin-top: ${({ registrationTab }) => (registrationTab ? '-10px' : '0px')};
    @media (min-width: 701px) {
      display: none;
    }
    .title {
      margin: 0;
    }
    .whiteSpace {
      display: ${({ registrationTab }) => (registrationTab ? 'inherit' : 'none')};
      position: absolute;
      top: -1px;
      background-color: #fff;
      height: 11px;
      width: 100%;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
`

export const SocialLink = styled.a`
  margin: 0 12px;
  @media (max-width: 425px) {
    margin: 0 15px 20px;
  }
`

export const SocialIcon = styled.img`
  width: 30px;
  height: 30px;
  transition-duration: 0.2s;
  @media (max-width: 1200px) and (min-width: 701px) {
    width: 24px;
    height: 24px;
  }
  &:hover {
    transform: scale(1.3);
  }
`
