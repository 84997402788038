import { Facebook } from '../static/svgs/Facebook'
import { Vkontakte } from '../static/svgs/Vkontakte'
import { Instagram } from '../static/svgs/Instagram'
import { Telegram } from '../static/svgs/Telegram'
import { Twitter } from '../static/svgs/Twitter'

export default {
  facebook: Facebook,
  vkontakte: Vkontakte,
  instagram: Instagram,
  telegram: Telegram,
  twitter: Twitter
}

export const socialsDomains = {
  facebook: 'facebook.',
  twitter: 'twitter.',
  telegram: 't.me',
  instagram: 'instagram.',
  vkontakte: 'vk.'
}
