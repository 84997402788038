import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import getConfig from 'next/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Header from '../Header'
import MetaContent from '../MetaContent'
import ProfileWindow from '../ProfileWindow'
import MobileMenu from '../MobileMenu'
import UserModal from '../UserModal'
import LogoutModal from '../LogoutModal'
import SuccessModal from '../SuccessModal'
import SearchModal from '../SearchModal'
import Loading from '../Loading'
import { Router } from '../../i18n'

import {
  ContainerDiv,
  ContentDiv,
  GapLogoDiv,
  WhiteGapImg,
  UMNWhiteImg,
  UMNBlackImg,
  ChildrenContainer,
  PlayIconDiv
} from './styles'

const { publicRuntimeConfig } = getConfig()

class Layout extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
    meta: PropTypes.instanceOf(Object),
    coverImageUrl: PropTypes.string,
    smallCoverImageUrl: PropTypes.string,
    isIndexPage: PropTypes.bool,
    changeWindowWidth: PropTypes.func,
    currentLanguage: PropTypes.string
  }

  static defaultProps = {
    children: null,
    meta: undefined,
    coverImageUrl: undefined,
    smallCoverImageUrl: undefined,
    isIndexPage: false,
    currentLanguage: 'ru',
    changeWindowWidth: () => {}
  }

  state = {
    isMobile: false,
    isTablet: false,
    isVideoPlayed: true,
    routerIsLoading: false
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions)
    this.checkWindowWidth()
    Router.events.on('routeChangeStart', this.showLoading)
    Router.events.on('routeChangeComplete', this.hideLoading)
    Router.events.on('routeChangeError', this.hideLoading)
  }

  showLoading = () => {
    this.setState({ routerIsLoading: true })
  }

  hideLoading = () => {
    this.setState({ routerIsLoading: false })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
    Router.events.off('routeChangeStart', this.showLoading)
    Router.events.off('routeChangeComplete', this.hideLoading)
    Router.events.off('routeChangeError', this.hideLoading)
  }

  checkWindowWidth = () => {
    const { changeWindowWidth } = this.props
    if (window.innerWidth <= 425) {
      this.setState({ isMobile: true, isTablet: false })
      changeWindowWidth('mobile')
    } else if (window.innerWidth <= 1000) {
      this.setState({ isMobile: false, isTablet: true })
      changeWindowWidth('tablet')
    } else {
      this.setState({ isMobile: false, isTablet: false })
      changeWindowWidth('desktop')
    }
  }

  updateDimensions = () => {
    this.checkWindowWidth()
  }

  render() {
    const {
      children, meta, coverImageUrl, isIndexPage, smallCoverImageUrl, currentLanguage
    } = this.props
    const {
      isMobile, isTablet, isVideoPlayed, routerIsLoading
    } = this.state

    return (
      <MobileMenu currentLanguage={currentLanguage} isTablet={isTablet}>
        {meta && <MetaContent meta={meta} />}
        <UserModal />
        <LogoutModal />
        <SuccessModal />
        <ProfileWindow />
        <SearchModal />
        <ContainerDiv>
          {routerIsLoading && <Loading type="line" className="routerLoading" />}
          <Header />
          <ContentDiv big={!isIndexPage}>
            {isIndexPage && (
              <div className="coverImgContainer">
                <video
                  poster="" // don't delete this line!
                  ref={ref => (this.videoRef = ref)}
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="bg__video"
                >
                  <source src="/static/videos/bg_18_19.webm#t=1.1" type="video/webm" />
                  <source src="/static/videos/bg_18_19.mp4#t=1.1" type="video/mp4" />
                </video>
              </div>
            )}
            {!isIndexPage && coverImageUrl && (
              <div className="coverImgContainer">
                <img
                  alt=""
                  className="coverImg"
                  src={
                    coverImageUrl.startsWith('http')
                      ? coverImageUrl
                      : `${publicRuntimeConfig.staticImagesServer}${coverImageUrl}`
                  }
                  srcSet={
                    coverImageUrl.startsWith('http')
                      ? `${coverImageUrl} 1440w, ${coverImageUrl.slice(0, coverImageUrl.lastIndexOf('.'))}@2x${coverImageUrl.slice(coverImageUrl.lastIndexOf('.'))} 2880w` // eslint-disable-line
                      : `${publicRuntimeConfig.staticImagesServer}${coverImageUrl}.jpg 1440w, ${publicRuntimeConfig.staticImagesServer}${coverImageUrl}@2x.jpg 2880w` // eslint-disable-line
                  }
                />
              </div>
            )}
            {smallCoverImageUrl && !coverImageUrl && (
              <div className="coverDiv">
                <div className="fadeBox">
                  <img
                    alt=""
                    className="smallCoverImg"
                    src={smallCoverImageUrl}
                    srcSet={`${smallCoverImageUrl} 1440w, ${smallCoverImageUrl.slice(0, smallCoverImageUrl.lastIndexOf('.'))}@2x${smallCoverImageUrl.slice(smallCoverImageUrl.lastIndexOf('.'))} 2880w`} // eslint-disable-line
                  />
                  <div className="fade" />
                </div>
                <div className="blockBox" />
              </div>
            )}
            {(coverImageUrl || smallCoverImageUrl) && (
              <GapLogoDiv>
                <div className="images">
                  {!isMobile ? (
                    <WhiteGapImg src="/static/images/white-gap@2x.png" />
                  ) : (
                    <WhiteGapImg src="/static/images/white-gap-mobile@2x.png" />
                  )}
                  <UMNWhiteImg src="/static/images/umn-white@2x.png" big={isIndexPage} />
                  {!isMobile && (
                    <UMNBlackImg
                      src={isIndexPage ? '/static/images/umn-black-home@2x.png' : '/static/images/umn-black@2x.png'}
                      big={isIndexPage}
                    />
                  )}
                  {isMobile && <UMNBlackImg src="/static/images/umn-black-mobile@2x.png" />}
                  {isIndexPage && (
                    <PlayIconDiv
                      onClick={() => {
                        if (isVideoPlayed) {
                          this.videoRef.pause()
                          this.setState({ isVideoPlayed: false })
                        } else {
                          this.videoRef.play()
                          this.setState({ isVideoPlayed: true })
                        }
                      }}
                    >
                      <FontAwesomeIcon
                        className={`icon ${!isVideoPlayed && 'playIcon'}`}
                        icon={isVideoPlayed ? 'pause' : 'play'}
                        size="2x"
                      />
                    </PlayIconDiv>
                  )}
                </div>
              </GapLogoDiv>
            )}
            <ChildrenContainer>{children}</ChildrenContainer>
          </ContentDiv>
        </ContainerDiv>
      </MobileMenu>
    )
  }
}

export default Layout
