/* eslint-disable max-len */
import PropTypes from 'prop-types'

export const Facebook = ({ style, className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" style={style} className={className}>
    <path
      fillRule="evenodd"
      d="M0 15C0 6.716 6.716 0 15 0c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15zm16.063 8.32v-8.161h2.253l.299-2.812h-2.552l.004-1.408c0-.733.07-1.126 1.123-1.126H18.6V7h-2.253c-2.707 0-3.66 1.364-3.66 3.658v1.689H11v2.812h1.687v8.16h3.376z"
    />
  </svg>
)

Facebook.propTypes = {
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string
}

Facebook.defaultProps = {
  style: {},
  className: undefined
}
