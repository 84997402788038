import React from 'react'

import { i18n } from '../../i18n'
import { SwitchContainerDiv, SwitchButtonA } from './styles'

class LanguageSwitch extends React.PureComponent {
  state = {
    currentLanguage: undefined
  }

  componentDidMount() {
    if (i18n.language && i18n.language !== this.state.currentLanguage) {
      this.setState({ currentLanguage: i18n.language })
    }
  }

  render() {
    const { currentLanguage } = this.state

    return (
      <SwitchContainerDiv>
        <SwitchButtonA
          role="button"
          checked={currentLanguage === 'en'}
          onClick={() => {
            i18n.changeLanguage('en')
            this.setState({ currentLanguage: 'en' })
          }}
        >
          EN
        </SwitchButtonA>
        <span>/</span>
        <SwitchButtonA
          role="button"
          checked={currentLanguage === 'ru'}
          onClick={() => {
            i18n.changeLanguage('ru')
            this.setState({ currentLanguage: 'ru' })
          }}
        >
          RU
        </SwitchButtonA>
      </SwitchContainerDiv>
    )
  }
}

export default LanguageSwitch
