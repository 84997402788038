import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  .LogoutModal_Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.70);
    z-index: 100;
    &::after {
      background-image: url('/static/images/cross-circle.svg');
      background-size: 50px 50px;
      content: "";
      width: 50px;
      height: 50px;
      position: absolute;
      right: 50px;
      top: 75px;
      cursor: pointer;
      @media (max-width: 700px) {
        display: none;
      }
    }
  }
  .LogoutModal_Content {
    position: absolute;
    max-height: 637px;
    width: 880px;
    height: 300px;
    top: calc(50vh - 150px);
    left: calc(50vw - 440px);
    background: #fff;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    border-radius: 10px;
    outline: none;
    @media (max-width: 1200px) {
      height: 230px;
      top: calc(50vh - 115px);
    }
    @media (max-width: 900px) {
      width: 90vw;
      left: 5vw;
    }
    @media (max-width: 700px) {
      height: 290px;
      top: calc(50vh - 145px);
    }
  }
`

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  .message {
    color: black;
    text-align: center;
    font-family: StolzlMedium, serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 45px;
    width: 90%;
    @media (max-width: 700px) {
      margin-bottom: 25px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    width: 80%;
    @media (max-width: 700px) {
      flex-direction: column;
      align-items: center;
    }
    .button {
      margin: 0 30px;
      @media (max-width: 1200px) {
        margin: 0 0;
      }
      @media (max-width: 700px) {
        margin: 7.5px 0;
      }
    }
  }
`
